import Button from "../../Button";

const WelcomeScreen = () => {
  return (
    <>
      <div className="bg-lightGreyBg max-sm:pb-72 pb-40 lg:pb-20"></div>
      <div id="welcomeScreenBackground">
        <h1 className="z-10 rainbow-text absolute text-center text-[42px] pb-12 lg:pt-28 2xl:pt-40 max-sm:absolute max-sm:top-0 max-sm:pt-36 md:text-[92px] w-[300px] md:w-[620px] lg:w-[970px] leading-[50px] md:leading-[110px]">
          Safe and fun casinos for every stake
        </h1>
        <div className="hidden max-sm:block mt-[-8px] absolute pt-24 z-10">
          <Button href="#getInTouch" data-scroll-to>
            Get in touch
          </Button>
        </div>
        <video
          className="h-[460px] md:h-[820px] xl:h-[920px] 2xl:h-[1000px] object-cover"
          autoPlay
          muted
          playsInline
          loop
          id="welcomeVideo"
        >
          <source src="/videos/welcome.mp4" type="video/mp4"></source>
        </video>
      </div>
    </>
  );
};
export default WelcomeScreen;
