import Head from "next/head";
import styles from "../styles/Home.module.scss";
import Navigation from "../components/Layouts/Navigation/Navigation";
import WelcomeScreen from "../components/Layouts/WelcomeScreen/WelcomeScreen";
import AboutUs from "../components/Layouts/AboutUs/AboutUs";
import Brands from "../components/Layouts/Brands/Brands";
import Office from "../components/Layouts/Office/Office";
import Partners from "../components/Layouts/Partners/Partners";
import GetInTouch from "../components/Layouts/GetInTouch/GetInTouch";
import Footer from "../components/Layouts/Footer";
import { NextPage } from "next";
import { install } from "resize-observer";
import { useEffect } from "react";

const Home: NextPage = () => {
  /* ResizeObserver on Safari 10.1-11 fix*/

  useEffect(() => {
    if (typeof window !== "undefined") {
      install();
    }
  });

  return (
    <div>
      <Head>
        <title>Hitz</title>
        <meta name="description" content="Hitz" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta content="/browserconfig.xml" name="msapplication-config" />
        <link rel="icon" href="/favicons/favicon.ico" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicons/android-chrome-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="256x256"
          href="/favicons/android-chrome-256x256.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="manifest"
          href="/favicons/site.webmanifest"
          crossOrigin="use-credentials"
        ></link>
      </Head>
      <header>
        <Navigation></Navigation>
      </header>
      <main data-scroll-section className={styles.main}>
        <WelcomeScreen></WelcomeScreen>
        <AboutUs></AboutUs>
        <Brands></Brands>
        <div className="bg-lightGrey w-full">
          <Office></Office>
          <Partners></Partners>
        </div>
        <GetInTouch></GetInTouch>
      </main>
      <footer data-scroll-section>
        <Footer></Footer>
      </footer>
    </div>
  );
};
export default Home;
