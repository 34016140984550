import Link from "next/link";
import Image from "next/image";
import NavLink from "./NavLink";
import ResponsiveNavLink from "./ResponsiveNavLink";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import React from "react";
import Button from "../../Button";
import { useLocomotiveScroll } from "react-locomotive-scroll";

const Navigation = () => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const { scroll } = useLocomotiveScroll();

  /* Scroll-to target mob */

  const scrollToTarget = (id: string) => {
    const target = document.querySelector(id);

    setOpen(false);

    scroll.scrollTo(target, 0);
  };

  useEffect(() => {
    document.body.classList.toggle("overflow-y-hidden", open);
  }, [open]);

  return (
    <>
      <div
        id="navBar"
        data-scroll-sticky
        className="w-full z-10 fixed bg-white"
      >
        <nav className={`max-w-[1920px] mx-auto ${open ? "h-screen" : ""}`}>
          {/* Primary Navigation Menu */}
          <div className="max-sm:m-0 my-4 mx-6 md:mx-24 lg:mx-24 xl:mx-28 2xl:mx-64">
            <div className="flex justify-between">
              <div className="flex w-full gap-8">
                {/* Logo */}
                <div className="flex items-center min-w-max max-sm:hidden">
                  <Link href="/">
                    <Image
                      src="/images/Logo.svg"
                      width={93}
                      height={29}
                      alt="Logo"
                      priority={true}
                    />
                  </Link>
                </div>
                {/* Navigation Links */}
                <div className="hidden space-x-8 lg:space-x-9 sm:flex ml-auto">
                  <NavLink href="#aboutUs" data-scroll-to>
                    About
                  </NavLink>
                  <NavLink href="#brands" data-scroll-to>
                    Brands
                  </NavLink>
                  <NavLink href="#office" data-scroll-to>
                    Culture
                  </NavLink>
                  <NavLink href="#partners" data-scroll-to>
                    Partners
                  </NavLink>
                  <Button href="#getInTouch" data-scroll-to>
                    Contact us
                  </Button>
                </div>
                {/* Hamburger */}
                <div className="flex items-center sm:hidden ml-auto mt-[22px] mb-[22px] mr-8">
                  <div
                    onClick={() => setOpen((open) => !open)}
                    className="inline-flex items-center justify-center rounded-md"
                  >
                    <div
                      className={`icon-hamburger w-[38px] h-[34px] ${
                        open ? "icon-active w-[38px] h-[34px]" : ""
                      }`}
                    >
                      <span className="svg-top"></span>
                      <span className="svg-bottom"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Responsive Navigation Menu */}
          {open && (
            <div id="mobileNav" className="block sm:hidden">
              <div className="bg-white mt-12 space-y-3">
                <ResponsiveNavLink
                  href=""
                  onClick={() => scrollToTarget("#aboutUs")}
                >
                  About
                </ResponsiveNavLink>
                <ResponsiveNavLink
                  href=""
                  onClick={() => scrollToTarget("#brands")}
                >
                  Brands
                </ResponsiveNavLink>
                <ResponsiveNavLink
                  href=""
                  onClick={() => scrollToTarget("#office")}
                >
                  Office
                </ResponsiveNavLink>
                <ResponsiveNavLink
                  href=""
                  onClick={() => scrollToTarget("#partners")}
                >
                  Partners
                </ResponsiveNavLink>
                <ResponsiveNavLink
                  href=""
                  onClick={() => scrollToTarget("#getInTouch")}
                >
                  Get in touch
                </ResponsiveNavLink>
              </div>
            </div>
          )}
        </nav>
      </div>
    </>
  );
};

export default Navigation;
