import Partner from "./Partner";
import { Swiper, SwiperSlide } from "swiper/react";

const customBreakpoints = {
  320: {
    width: 320,
    slidesPerView: 2,
    spaceBetween: 55,
  },
  640: {
    width: 640,
    slidesPerView: 4,
    spaceBetween: 110,
  },
};

const Partners = () => {
  return (
    <>
      <div
        id="partners"
        data-scroll
        className="max-w-[1920px] mx-auto pt-28 md:pt-52 xl:pt-[270px] pb-36 md:pb-48"
      >
        <div className="rainbow-heading px-7 md:pl-24 2xl:pl-64">
          <h2 className="text-[42px] md:text-[92px] pb-10 md:pb-16">
            Our <span className="max-sm:font-GilroyBold font-GilroySemiBold">Partners</span>
          </h2>
        </div>
        <div id="partnersContainer">
          <Swiper
            breakpoints={customBreakpoints}
            className="mb-3"
            data-scroll
            spaceBetween={1}
            data-scroll-direction="horizontal"
            data-scroll-speed="-0.5"
            slidesPerView="auto"
            loop={true}
          >
            <div className="inline-flex">
              <SwiperSlide>
                <Partner
                  alt="Betsoft"
                  src="/images/partners/betsoft.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="ezugi0old"
                  src="/images/partners/ezugi0old.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="evoplay-entertainment"
                  src="/images/partners/evoplay-entertainment.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="bigtimegaming"
                  src="/images/partners/bigtimegaming.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="reelplay"
                  src="/images/partners/reelplay.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="thunderkick"
                  src="/images/partners/thunderkick.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="wazdan"
                  src="/images/partners/wazdan.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="worldmatch"
                  src="/images/partners/worldmatch.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="yggdrasil"
                  src="/images/partners/yggdrasil.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="relaxgaming"
                  src="/images/partners/relaxgaming.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="yolted"
                  src="/images/partners/yolted.svg"
                ></Partner>
              </SwiperSlide>
            </div>
          </Swiper>

          <Swiper
            breakpoints={customBreakpoints}
            className="mb-3"
            data-scroll
            spaceBetween={1}
            data-scroll-direction="horizontal"
            data-scroll-speed="0.5"
            slidesPerView="auto"
            loop={true}
          >
            <div className="inline-flex">
              <SwiperSlide>
                <Partner
                  alt="playson"
                  src="/images/partners/playson.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="netent"
                  src="/images/partners/netent.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="luckystreak"
                  src="/images/partners/luckystreak.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="spinomenal"
                  src="/images/partners/spinomenal.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="playngo"
                  src="/images/partners/playngo.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="pgsoft"
                  src="/images/partners/pgsoft.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="evolution"
                  src="/images/partners/evolution.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="boominggames"
                  src="/images/partners/boominggames.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="authenticgaming"
                  src="/images/partners/authenticgaming.svg"
                ></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner alt="ezugi" src="/images/partners/ezugi.svg"></Partner>
              </SwiperSlide>
              <SwiperSlide>
                <Partner
                  alt="elkstudios"
                  src="/images/partners/elkstudios.svg"
                ></Partner>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default Partners;
