import React, { ReactNode } from "react";
import Image from "next/image";
interface Props {
  src: string;
  alt: string;
}
const Partner = ({ src, alt }: Props) => (
  <div className="partnerBlock">
    <Image src={src} alt={alt} width="0" height="0" />
  </div>
);

export default Partner;
