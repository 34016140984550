const GetInTouch = () => {
  return (
    <>
      <div
        id="getInTouch"
        data-scroll
        className="flex h-auto pt-28 pb-32 px-7 w-full items-center justify-center text-center"
      >
        <div>
          <h2 className="pb-10 text-white text-[65px]">
            Let&apos;s get in touch
          </h2>
          <div>
            <div className="max-sm:grid max-sm:gap-3 sm:inline-flex justify-center gap-9 align-middle">
              <a
                href="mailto:info@hitzgaming.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex justify-center items-center h-[120px] w-[300px] md:w-[330px] bg-white rounded-2xl hover:invert transition duration-200 ease-in-out">
                  <svg
                    className="inline-block mr-3"
                    width="17"
                    height="13"
                    viewBox="0 0 17 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.875 0H2.125C1.5616 0.000586561 1.02145 0.218672 0.623064 0.606404C0.22468 0.994137 0.000602675 1.51985 0 2.06818V10.9318C0.000602675 11.4802 0.22468 12.0059 0.623064 12.3936C1.02145 12.7813 1.5616 12.9994 2.125 13H14.875C15.4384 12.9994 15.9786 12.7813 16.3769 12.3936C16.7753 12.0059 16.9994 11.4802 17 10.9318V2.06818C16.9994 1.51985 16.7753 0.994137 16.3769 0.606404C15.9786 0.218672 15.4384 0.000586561 14.875 0ZM14.3369 3.42099L8.87263 7.55736C8.76608 7.63798 8.63496 7.68174 8.5 7.68174C8.36504 7.68174 8.23392 7.63798 8.12737 7.55736L2.66308 3.42099C2.59888 3.37381 2.54496 3.31467 2.50444 3.24703C2.46392 3.17938 2.43763 3.10458 2.42707 3.02695C2.41652 2.94933 2.42192 2.87044 2.44297 2.79487C2.46401 2.7193 2.50028 2.64855 2.54966 2.58673C2.59904 2.52492 2.66055 2.47328 2.73062 2.4348C2.80068 2.39632 2.87791 2.37178 2.9578 2.36261C3.0377 2.35343 3.11867 2.3598 3.19602 2.38134C3.27336 2.40288 3.34554 2.43917 3.40835 2.4881L8.5 6.3423L13.5917 2.4881C13.719 2.39452 13.879 2.35348 14.0372 2.37385C14.1953 2.39422 14.3389 2.47436 14.4369 2.59694C14.5348 2.71953 14.5792 2.8747 14.5605 3.02891C14.5418 3.18312 14.4615 3.32397 14.3369 3.42099Z"
                      fill="#060606"
                    />
                  </svg>
                  info@hitzgaming.com
                </div>
              </a>
              <a
                href="https://t.me/hey.hitzgaming"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex justify-center items-center h-[120px] w-[300px] md:w-[330px] bg-white rounded-2xl hover:invert transition duration-200 ease-in-out">
                  <svg
                    className="inline-block mr-3"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.2956 0.141264C18.2956 0.141264 20.1456 -0.551304 19.9908 1.13052C19.9399 1.82309 19.4775 4.2473 19.1175 6.86901L17.8842 14.6358C17.8842 14.6358 17.7813 15.7737 16.8561 15.9716C15.9313 16.1691 14.5437 15.279 14.2866 15.0811C14.0809 14.9325 10.4324 12.7062 9.14763 11.6182C8.78763 11.3211 8.37621 10.7277 9.19906 10.0352L14.5947 5.08799C15.2114 4.49462 15.828 3.10948 13.2585 4.7913L6.06339 9.49071C6.06339 9.49071 5.24102 9.98579 3.69961 9.54054L0.358706 8.55083C0.358706 8.55083 -0.874609 7.80889 1.2325 7.06695C6.37195 4.74193 12.6933 2.36754 18.2946 0.141264H18.2956Z"
                      fill="black"
                    />
                  </svg>
                  hey.hitzgaming
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GetInTouch;
