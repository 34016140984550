import Image from "next/image";

const AboutUs = () => {
  return (
    <>
      <div
        id="aboutUs"
        data-scroll
        className="w-full pt-20 md:pt-28 lg:pt-36 2xl:pt-60"
      >
        <div className="max-w-[1920px] mx-auto">
          <div className="px-7 pb-12 text-lg leading-6 max-w-[360px] max-sm:mx-auto md:pb-24 md:max-w-full md:text-[38px] md:leading-[51px] md:w-[790px] md:px-24 xl:w-[1000px] 2xl:pl-64 2xl:w-[1250px]">
            <p className="font-GilroySemiBold">
              Our creative approach, fueled by innovative ideas and substantial
              expertise, brings state-of-the art products to life. Our main goal
              is creating profit through fun and excitement, and we’ve
              successfully achieved it with all our projects thus far.
            </p>
          </div>
          <div className="max-sm:bg-greyBg flex justify-end sm:mb-16 lg:float-right lg:pl-16">
            <Image
              src="/images/fun_asset.png"
              width={498}
              height={543}
              className="bg-greyBg max-sm:m-auto  sm:mr-6 w-auto h-[300px] md:h-[350px] xl:h-[380px] 2xl:h-[543px] sm:object-cover sm:rounded-2xl md:mr-24 xl:mr-28 2xl:mr-60"
              alt="Fun asset"
            />
          </div>
          <div className="max-sm:hidden w-[260px] pl-7 md:pl-24 md:w-[420px] lg:p-0 lg:pt-24 lg:w-[330px] lg:float-right xl:pt-28 2xl:pt-60">
            <p className="text-lg text-darkGrey">
              We have one thing in common - top performance. The
              Hitz team consists of brilliant, motivated individuals who do
              everything in their power to ensure our spot as entertainment
              industry leaders stays secure.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutUs;
