import React, { ReactNode } from "react";
interface Props {
  children?: ReactNode;
  href: string;
}

const Button = ({ children, href, ...props }: Props) => (
  <a
    href={href}
    {...props}
    className={`bg-blue text-white text-center border border-blue rounded-full  min-w-[136px] px-[26px] py-[11px] max-sm:px-[34px] max-sm:py-[18px] max-sm:w-[171px] hover:text-black hover:bg-white transition duration-200 ease-in-out`}
  >
    {children}
  </a>
);

export default Button;
