import React, { ReactNode } from "react";
interface Props {
  children?: ReactNode;
  href: string;
}
const NavLink = ({ children, href, ...props }: Props) => (
  <a
    href={href}
    {...props}
    className={`inline-flex items-center text-base leading-5 transition duration-200 ease-in-out 
    ${"text-black hover:text-grey"}`}
  >
    {children}
  </a>
);

export default NavLink;
