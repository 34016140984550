import React, { ReactNode } from "react";
interface Props {
  children?: ReactNode;
  title?: string;
  href: string;
}
const BrandCard = ({ children, title, href }: Props) => (
  <a
    href={href}
    className="m-auto xl:w-full 2xl:w-full"
    target="_blank"
    rel="noreferrer"
  >
    <div
      className="brandBlock flex flex-col items-center justify-center text-grey border-2 border-lightGrey mx-auto rounded-3xl w-[300px] h-[380px] max-[359px]:w-[260px] md:w-[620px] md:h-[267px] xl:w-auto xl:h-[405px] 2xl:h-[556px]
     hover:bg-lightGrey transition duration-200 ease-in-out max-sm:bg-lightGrey"
    >
      <div className="brandBlock_arrow self-end m-[12px]"></div>
      <div className="m-auto">{children}</div>
      <span className="text-center text-base mt-auto mb-8 max-w-[130px]">
        {title}
      </span>
    </div>
  </a>
);

export default BrandCard;
