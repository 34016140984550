import Link from "next/link";
import React, { ReactNode } from "react";
interface Props {
  children?: ReactNode;
  href: string;
  onClick: any;
}

const ResponsiveNavLink = ({ children, href, ...props }: Props) => (
  <Link
    href={href}
    {...props}
    className={
      "block bg-lightGrey w-[300px] h-[65px] leading-[65px] rounded-xl mx-auto text-[32px] text-center text-black transition duration-200 ease-in-out hover:bg-blue hover:text-white focus:text-white"
    }
  >
    {children}
  </Link>
);

export default ResponsiveNavLink;
