import Image from "next/image";

const Office = () => {
  return (
    <>
      <div
        id="office"
        data-scroll
        className="max-w-[1920px] mx-auto pt-20 md:pt-[170px] xl:pt-[150px] 2xl:pt-[200px] px-7 md:px-24 2xl:px-64"
      >
        <div className="rainbow-heading">
          <h2 className="text-[42px] md:text-[92px] pb-10 md:pb-16">
            Office <span className="max-sm:font-GilroyBold font-GilroySemiBold">Culture</span>
          </h2>
          </div>
        <div className="lg:flex lg:gap-x-5 justify-left 2xl:gap-x-10">
          <div className="md:bg-white md:p-[34px] rounded-[44px] md:mb-5 md:max-w-[620px] lg:w-[690px] xl:w-[800px] lg:h-fit">
            <h2 className="pb-3 text-[32px] sm:text-[64px]">Satisfaction is key</h2>
            <h3 className="pb-7 leading-6 text-lg font-GilroySemiBold sm:text-[32px] sm:leading-[43px]">
              How does the Hitz team provide safe online casino entertainment
              for all?
            </h3>
            <p className="pb-16 leading-7 text-lg text-grey max-sm:text-black max-w-[520px]">
              Our offices and working culture are employee-friendly, and that is
              clearly reflected in our products. We keep things fun, which helps
              our team remain focused and inspired to provide fun and safe
              products for every player.
            </p>
          </div>
          <div className="max-w-[300px] md:max-w-[620px] xl:max-w-[650px] 2xl:max-w-[810px]">
            <Image
              src="/images/office.png"
              width={800}
              height={810}
              className="h-[350px] object-cover rounded-[22px] md:rounded-[42px] md:h-[650px] lg:[h-full] lg:h-[800px]"
              alt="Office"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Office;
