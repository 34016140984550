import Image from "next/image";
import BrandCard from "./BrandCard";

const Brands = () => {
  return (
    <>
      <div
        id="brands"
        className="w-full max-w-[1920px] mx-auto pb-44 px-7 md:px-24 2xl:px-64 pt-36 2xl:pt-52"
      >
        <div className="pb-10 md:pb-[72px] lg:pb-40 2xl:pb-52">
          <div className="rainbow-heading">
            <h2 className="text-[42px] md:text-[92px] ">
              Our <span className="max-sm:font-GilroyBold font-GilroySemiBold">Brands</span>
            </h2>
          </div>
          <p className="text-grey text-lg max-w-[600px]">
            Where there’s Hitz - there’s high quality, limitless creativity, and
            exceptional attention to detail. Each Hitz brand is unique in its
            own way, with one common component - fun.
          </p>
        </div>
        <div className="grid xl:grid-cols-3 gap-3 md:gap-[18px] 2xl:gap-10">
          <BrandCard href="https://www.trickz.com" title="Put Fun First">
            <Image
              src="/images/trickz.svg"
              width={220}
              height={90}
              alt="Trickz"
            />
          </BrandCard>
          <BrandCard href="https://www.wisho.com/" title="Where magic is born">
            <Image
              src="/images/wisho.svg"
              width={232}
              height={116}
              alt="Wisho"
            />
          </BrandCard>
          <BrandCard href="https://www.winnerz.com/" title="Truly Fun">
            <Image
              src="/images/winnerz.svg"
              width={272}
              height={112}
              alt="Winnerz"
            />
          </BrandCard>
        </div>
      </div>
    </>
  );
};
export default Brands;
