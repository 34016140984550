const Footer = () => {
  return (
    <>
      <div className="pb-16 pt-24 text-center">
        <p className="leading-10 text-[34px] font-GilroyBold mb-14 sm:mb-8">
          HITZGAMING
        </p>
        <div className="grid grid-cols-2 w-fit gap-y-3.5 gap-x-11 text-base text-left mb-20 max-sm:pl-7 sm:mb-6 sm:inline-flex sm:gap-x-8">
          <div>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="#aboutUs"
              data-scroll-to
            >
              About us
            </a>
          </div>
          <div>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="#partners"
              data-scroll-to
            >
              Partners
            </a>
          </div>
          <div>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="#brands"
              data-scroll-to
            >
              Brands
            </a>
          </div>
          <div>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="#getInTouch"
              data-scroll-to
            >
              Get in touch
            </a>
          </div>
          <div>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="#office"
              data-scroll-to
            >
              Office
            </a>
          </div>
        </div>
        <ul className="text-lg font-GilroySemiBold mb-7">
          <li>
            <a
              className="pr-10 hover:text-grey transition duration-200 ease-in-out"
              href="https://www.trickz.com/"
              target="_blank"
              rel="noreferrer"
            >
              Trickz
            </a>
          </li>
          <li>
            <a
              className="hover:text-grey transition duration-200 ease-in-out"
              href="https://www.wisho.com/"
              target="_blank"
              rel="noreferrer"
            >
              Wisho
            </a>
          </li>
          <li>
            <a
              className="pl-10 hover:text-grey transition duration-200 ease-in-out"
              href="https://www.winnerz.com/"
              target="_blank"
              rel="noreferrer"
            >
              Winnerz
            </a>
          </li>
          <li>
            <a
              className="pl-10 hover:text-grey transition duration-200 ease-in-out"
              href="https://www.celebrino.com/"
              target="_blank"
              rel="noreferrer"
            >
              Celebrino
            </a>
          </li>
        </ul>
        <p className="font-GilroySemiBold text-grey">
          &copy; {new Date().getFullYear()} - HitzGaming
        </p>
        <p className="font-GilroySemiBold text-grey">All rights reserved.</p>
      </div>
    </>
  );
};
export default Footer;
